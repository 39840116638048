const controlPaths = {
  interactions: 'sidebar.Information.interactions',
  scenario: 'sidebar.Environment.scenario',
  'Time of Day': 'sidebar.Environment.Time of Day',
  showPeople: 'sidebar.Environment.people.children.showPeople',
  addPeople: 'sidebar.Environment.people.children.addPeople',
  spaces: 'sidebar.Wayfinding.spaces',
  views: 'sidebar.Wayfinding.views',
  movement: 'sidebar.Wayfinding.movement.children.movement',
  movementSpeed: 'sidebar.Wayfinding.movement.children.movementSpeed',
  guidedTours: 'sidebar.Wayfinding.guidedTours.children.guidedTours',
  guidedToursSpeed: 'sidebar.Wayfinding.guidedTours.children.guidedToursSpeed',
  ShouldHideSidebar: 'sidebar.General Settings.ShouldHideSidebar',
  InvertLookX: 'sidebar.General Settings.InvertLookX',
  InvertLookY: 'sidebar.General Settings.InvertLookY',
  LookSensitivity: 'sidebar.General Settings.LookSensitivity',
  FieldOfView: 'sidebar.General Settings.FieldOfView',
  eyeHeight: 'sidebar.General Settings.eyeHeight',
  orbitModeBackground: 'sidebar.General Settings.orbitModeBackground',
  showMode: 'sidebarHeader.showMode',
  inputMethod: 'sidebarHeader.inputMethod',

  cameraMode: 'sidebar.Tools.cameraMode',
  measurementMode: 'sidebar.Tools.measurementMode',
  slicingMode: 'sidebar.Tools.slicingMode',
  showQuickCapture: 'sidebar.General Settings.showQuickCapture',
  showJoysticks: 'sidebar.General Settings.showJoysticks',
  isRightSidebar: 'sidebar.General Settings.isRightSidebar',
};

export default controlPaths;
