import { useState } from 'react';
import { Stack, Typography, useMediaQuery, Theme } from '@mui/material';

import useStore from 'store/store';
import { getProject } from 'config';
import useStyles from 'hooks/useStyles';

import BuildInfo from 'sections/title/BuildInfo/BuildInfo';

import TitleLogo from 'sections/title/TitleLogo/TitleLogo';
import TitlePageMobile from './TitlePageMobile/TitlePageMobile';
import TitlePageMobileLandscape from './TitlePageMobileLandscape/TitlePageMobileLandscape';
import TitlePageVideo from './TitlePageVideo/TitlePageVideo';

import rcLogo from 'assets/images/rc-logo.png';
import nclLogo from 'assets/images/ncl-logo.png';
import bwLogo from 'assets/images/bw-logo.svg';

import getStyles from './TitlePage.styles';

const content = {
  j35: { ship: 'ILMA', area: 'GRAND SUITE' },
  k35: { ship: 'LUMINARA', area: 'GRAND SUITE' },
  spa: { ship: 'NORWEGIAN JOY', area: 'MANDARA SPA' },
  publicDemo: { ship: null, area: 'DEMO SUITE' },
  stingray: { ship: null, area: 'L35 Suite Options' },
  az: { ship: null, area: 'White Card Deck Demo' },
};

const TitlePage = () => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { shareId, setShowTitlePage } = useStore();

  const project = getProject(shareId);
  let company;

  if (project === 'j35' || project === 'k35' || project === 'stingray') {
    company = 'rc';
  } else if (project === 'spa') {
    company = 'ncl';
  } else if (project === 'publicDemo') {
    company = 'bw';
  } else {
    company = 'bw';
  }

  const projectContent = content[project as keyof typeof content];

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isMobileLandscape = useMediaQuery((theme: Theme) => `(max-width: 1080px) and (max-height: 700px)`);

  const styles = useStyles(getStyles);

  if (isMobile) {
    return <TitlePageMobile hideTitlePage={() => setShowTitlePage(false)} company={company} content={projectContent} />;
  }

  if (isMobileLandscape) {
    return (
      <TitlePageMobileLandscape
        hideTitlePage={() => setShowTitlePage(false)}
        company={company}
        content={projectContent}
      />
    );
  }

  return (
    <>
      {isVideoPlaying && <TitlePageVideo setIsVideoPlaying={setIsVideoPlaying} />}
      <Stack sx={styles.titlePage} justifyContent="space-between" onClick={() => setShowTitlePage(false)}>
        <Stack sx={styles.build} direction="row" justifyContent="space-between">
          <TitleLogo />
          <BuildInfo />
        </Stack>

        <Stack sx={styles.project} alignItems="center" spacing={6.25}>
          <img width="420" src={company === 'bw' ? bwLogo : company === 'rc' ? rcLogo : nclLogo} alt="" />
          <Typography sx={styles.projectText} variant="h3">
            {projectContent?.ship}
          </Typography>
          <Typography sx={styles.projectText} variant="h2">
            {projectContent?.area}
          </Typography>
        </Stack>

        <Stack sx={styles.info} justifyContent="flex-end">
          <Typography variant="h3">tap or click to continue</Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default TitlePage;
