import { Stack, Typography } from '@mui/material';

const Label = ({ icon, label, children }: any) => {
  if (!label) {
    return children;
  }

  return (
    <Stack spacing={2} my={1}>
      <Stack direction="row" spacing={2} alignItems="center">
        {icon && (
          <Stack
            sx={{ width: '40px', height: '40px', borderRadius: '5px', backgroundColor: '#1B1B1B' }}
            justifyContent="center"
            alignItems="center"
          >
            {icon}
          </Stack>
        )}
        {label && <Typography variant="body2">{label}</Typography>}
      </Stack>
      {children}
    </Stack>
  );
};

export default Label;
