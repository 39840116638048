import { Stack, Box, Typography, IconButton, useMediaQuery, Theme } from '@mui/material';
import { Menu, ArrowRight } from '@mui/icons-material';

const ShowQuickCapture = () => {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack
        direction={isMedium ? 'column' : 'row'}
        spacing={isLarge ? 1 : 2}
        justifyContent={isMedium ? 'flex-end' : 'flex-start'}
        alignItems="center"
      >
        <Stack sx={{ whiteSpace: 'nowrap' }} direction="row" alignItems="center" spacing={isMedium ? 0 : 2}>
          <IconButton>
            <Menu />
          </IconButton>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">General settings</Typography>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">Show quick capture</Typography>
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          Enabling this setting will display a quick capture button on the screen.
        </Typography>
        <Box
          sx={{
            width: '30px',
            height: '30px',
            padding: '3px',
            position: 'relative',
            border: '2px solid white',
            borderRadius: '50%',
            backgroundClip: 'content-box',
            backgroundColor: '#fff',
          }}
        />
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          You can use this button to take a screen capture of the current view without having to use the{' '}
          <span style={{ color: '#FFF' }}>Camera Tool</span>.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ShowQuickCapture;
