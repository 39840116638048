import { Stack, Typography, IconButton, useMediaQuery, Theme } from '@mui/material';
import { Menu, ArrowRight } from '@mui/icons-material';

import { MockButton } from 'components/ui';

const FieldOfView = () => {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack
        direction={isMedium ? 'column' : 'row'}
        spacing={isLarge ? 1 : 2}
        justifyContent={isMedium ? 'flex-end' : 'flex-start'}
        alignItems="center"
      >
        <Stack sx={{ whiteSpace: 'nowrap' }} direction="row" alignItems="center" spacing={isMedium ? 0 : 2}>
          <IconButton color="inherit" aria-label="menu">
            <Menu />
          </IconButton>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">General settings</Typography>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">Field of view</Typography>
          <ArrowRight sx={{ color: '#555' }} />
        </Stack>

        <Stack sx={{ pl: isMedium ? 5 : 0 }} direction="row" spacing={1}>
          <MockButton sx={{ minWidth: '70px' }} label="low" />
          <MockButton sx={{ minWidth: '70px' }} label="medium" selected />
          <MockButton sx={{ minWidth: '70px' }} label="high" />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          Changing the <span style={{ color: '#FFF' }}>Field of view (FOV) </span>increases or decreases how much the
          camera can see.
        </Typography>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          A <span style={{ color: '#FFF' }}>High </span> FOV allows the camera lens to capture more but increases the
          amount of distortion. A <span style={{ color: '#FFF' }}>Low </span> FOV will show less but allows you to view
          a space more like you would in reality with little or no distortion.{' '}
          <span style={{ color: '#FFF' }}>Medium </span> is a balance between the two.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default FieldOfView;
