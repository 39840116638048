import { SingleSelect, MultiSelect, ButtonSidebar, Switch, Label } from 'components/ui';
import ControlGroup from 'sections/sidebar/ControlGroup/ControlGroup';

import useStore from 'store/store';
import updateFunctionsMap from 'store/updateFunctionsMap';
import icons from 'config/sidebar/icons';

const SidebarControl = ({ control, controlName, isNested = false }: any) => {
  const { type, mainLabel, options: controlOptions, context, children } = control;
  const icon = icons[controlName as keyof typeof icons];

  const value = useStore((state) => state[controlName as keyof typeof state]);
  const update = useStore(
    (state) => state[updateFunctionsMap[controlName as keyof typeof updateFunctionsMap] as keyof typeof state],
  );

  const contextValue = context ? useStore((state) => state[context as keyof typeof state].value) : null;
  const options = context ? controlOptions[contextValue] : controlOptions;

  switch (type) {
    case 'singleSelect':
      return isNested ? (
        <SingleSelect onChange={update} value={value.value} options={options} disabled={value.disabled} />
      ) : (
        <ControlGroup label={mainLabel} icon={icon}>
          <SingleSelect onChange={update} value={value.value} options={options} disabled={value.disabled} />
        </ControlGroup>
      );
    case 'multiSelect':
      return isNested ? (
        <MultiSelect onChange={update} values={value.value} options={options} disabled={value.disabled} />
      ) : (
        <ControlGroup label={mainLabel} icon={icon}>
          <MultiSelect onChange={update} values={value.value} options={options} disabled={value.disabled} />
        </ControlGroup>
      );
    case 'switch':
      return <Switch onChange={update} label={mainLabel} value={value.value} disabled={value.disabled} />;
    case 'button':
      return <ButtonSidebar onClick={update} label={mainLabel} icon={icon} />;
    case 'controlGroup':
      return (
        <ControlGroup label={mainLabel} icon={icon}>
          {Object.keys(children).map((child) => (
            <SidebarControl key={child} control={children[child]} controlName={child} isNested={true} />
          ))}
        </ControlGroup>
      );
    case 'label':
      return <Label label={mainLabel} icon={icon} />;
    default:
      return null;
  }
};

export default SidebarControl;
