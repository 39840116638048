import get from 'lodash.get';
import set from 'lodash.set';

import { getConfig } from 'config';
import controlPaths from 'config/sidebar/controlPaths';
import useStore from 'store/store';

const createDescriptor = (property: keyof typeof controlPaths, value: any) => {
  const getState = useStore.getState;
  const { shareId } = getState();

  const config = getConfig(shareId);
  const propertyConfig = get(config, controlPaths[property]);

  if (!propertyConfig) {
    return null;
  }

  const { object = {}, objectPath, multipleArguments } = propertyConfig;

  let updatedValue = value;

  // If there are multiple arguments in format "arg1 arg2", insert value as argument at {value} placeholder
  if (multipleArguments) {
    updatedValue = multipleArguments.replace('{value}', value);
  }

  let descriptor;

  // If interaction is stateless button, just send descriptor without any value
  if (!updatedValue && !objectPath) {
    descriptor = object;
  } else {
    descriptor = set(object, objectPath, updatedValue);
  }

  return descriptor;
};

export default createDescriptor;
