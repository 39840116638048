import {
  Layers,
  Store,
  Brightness7,
  PermMedia,
  DirectionsRun,
  HdrWeak,
  PlayCircleOutline,
  CameraAlt,
  Straighten,
  ContentCut,
  PersonAdd,
  People,
} from '@mui/icons-material';

const icons = {
  tiers: <Store />,
  spaces: <Layers />,
  scenario: <PermMedia />,
  'Time of Day': <Brightness7 />,
  views: <PermMedia />,
  movement: <DirectionsRun />,
  guidedTours: <PlayCircleOutline />,
  interactions: <HdrWeak />,
  cameraMode: <CameraAlt />,
  measurementMode: <Straighten />,
  slicingMode: <ContentCut />,
  addPeople: <PersonAdd />,
  people: <People />,
};

export default icons;
