import { Stack, Typography, IconButton, useMediaQuery, Theme } from '@mui/material';
import { Menu, ArrowRight } from '@mui/icons-material';

import { MockButton } from 'components/ui';

const LookSensitivity = () => {
  const isLarge = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <Stack pt={4} px={2} pb={2} spacing={3}>
      <Stack
        direction={isMedium ? 'column' : 'row'}
        spacing={isLarge ? 1 : 2}
        justifyContent={isMedium ? 'flex-end' : 'flex-start'}
        alignItems="center"
      >
        <Stack sx={{ whiteSpace: 'nowrap' }} direction="row" alignItems="center" spacing={isMedium ? 0 : 2}>
          <IconButton color="inherit" aria-label="menu">
            <Menu />
          </IconButton>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">General settings</Typography>
          <ArrowRight sx={{ color: '#555' }} />
          <Typography variant="body2">Look sensitivity</Typography>
          <ArrowRight sx={{ color: '#555' }} />
        </Stack>

        <Stack sx={{ pl: isMedium ? 5 : 0 }} direction="row" spacing={1}>
          <MockButton sx={{ minWidth: '70px' }} label="low" />
          <MockButton sx={{ minWidth: '70px' }} label="medium" selected />
          <MockButton sx={{ minWidth: '70px' }} label="high" />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          This option allows you to alter the sensitivity when looking around.{' '}
          <span style={{ color: '#FFF' }}>Low</span> means looking around more slowly, allowing for more precise
          control. <span style={{ color: '#FFF' }}>High</span> will turn the view more quickly and sacrifices precision
          for speed. <span style={{ color: '#FFF' }}>Medium</span> is a balance between the two.
        </Typography>
        <Typography sx={{ color: '#A6A6A6' }} variant="body1">
          The three levels of sensitivity apply to both the <span style={{ color: '#FFF' }}>Walk</span> and{' '}
          <span style={{ color: '#FFF' }}>Fly</span> modes.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default LookSensitivity;
