const stingrayConfig = {
  topControls: {
    showMode: {
      ui: 'interaction',
      type: 'buttonIconCycle',
      defaultValue: '3D',
      objectPath: 'CallFunctionRequest.Arguments',
      object: {
        CallFunctionRequest: { FunctionName: 'Toggle2DMode', Arguments: null },
      },
      options: [
        {
          icon: '',
          value: '3D',
          enabled: false,
        },
        {
          icon: '',
          value: '2D',
          enabled: true,
        },
      ],
    },
  },
  sidebarHeader: {
    showMode: {
      ui: 'interaction',
      type: 'buttonIconCycle',
      defaultValue: '2D',
      objectPath: 'CallFunctionRequest.Arguments',
      object: {
        CallFunctionRequest: { FunctionName: 'Toggle2DMode', Arguments: null },
      },
      options: [
        {
          icon: '',
          value: '3D',
          enabled: false,
        },
        {
          icon: '',
          value: '2D',
          enabled: true,
        },
      ],
    },
    inputMethod: {
      ui: 'interaction',
      type: 'buttonIconCycle',
      defaultValue: false,
      objectPath: 'CallFunctionRequest.Arguments',
      object: {
        CallFunctionRequest: { FunctionName: 'SetAlternativeInputMapping', Arguments: null },
      },
      options: [
        {
          icon: 'bath option 1',
          value: false,
          enabled: false,
        },
        {
          icon: '',
          value: true,
          enabled: true,
        },
      ],
    },
  },
  sidebar: {
    Wayfinding: {
      spaces: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Spaces',
        defaultValue: null,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetActiveArea',
            Arguments: null,
          },
        },
        options: [
          { groupLabel: 'L35 Signature Suites' },
          {
            label: 'option 1',
            value: 'option 1',
            thumbnail: '/assets/images/stingray/spaces/option1.webp',
          },

          {
            label: 'option 2',
            value: 'option 2',
            thumbnail: '/assets/images/stingray/spaces/option2.webp',
          },
          {
            label: 'option 3a',
            value: 'file A',
            thumbnail: '/assets/images/stingray/spaces/filea.webp',
          },
          {
            label: 'option 3',
            value: 'option 3',
            thumbnail: '/assets/images/stingray/spaces/option3.webp',
          },
        ],
      },
      movement: {
        ui: 'interaction',
        type: 'controlGroup',
        mainLabel: 'Movement',
        children: {
          movementSpeed: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: 0.5,
            objectPath: 'CallFunctionRequest.Arguments',
            object: {
              CallFunctionRequest: {
                FunctionName: 'SetMovementSpeed',
                Arguments: null,
              },
            },
            options: [
              {
                label: '>',
                value: 0.5,
              },
              {
                label: '>>',
                value: 1,
              },
              {
                label: '>>>',
                value: 1.5,
              },
              {
                label: '>>>>',
                value: 2,
              },
            ],
          },
          movement: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: 'orbit',
            objectPath: 'CallFunctionRequest.Arguments',
            object: {
              CallFunctionRequest: {
                FunctionName: 'SetActivePawn',
                Arguments: null,
              },
            },
            options: [
              {
                label: 'walk',
                value: 'walk',
              },

              {
                label: 'fly',
                value: 'fly',
              },
              {
                label: 'orbit',
                value: 'orbit',
              },
            ],
          },
        },
      },
      views: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Views',
        defaultValue: null,
        objectPath: 'TeleportPlayer',
        context: 'spaces',
        options: {
          'option 1': [
            {
              label: 'view 1',
              value: {
                Location: { x: '775.865581', y: '-4051.98025', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-221.66424' },
              },
              thumbnail: '/assets/images/stingray/views/option1_view1.webp',
            },
            {
              label: 'view 2',
              value: {
                Location: { x: '613.608477', y: '-3507.931184', z: '121.661162' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-139.999999' },
              },
              thumbnail: '/assets/images/stingray/views/option1_view2.webp',
            },
            {
              label: 'view 3',
              value: {
                Location: { x: '643.126077', y: '-3427.770407', z: '121.661162' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-189.775005' },
              },
              thumbnail: '/assets/images/stingray/views/option1_view3.webp',
            },
            {
              label: 'view 4',
              value: {
                Location: { x: '771.355574', y: '-4237.70779', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-215.803363' },
              },
              thumbnail: '/assets/images/stingray/views/option1_view4.webp',
            },
          ],
          'option 2': [
            {
              label: 'view 1',
              value: {
                Location: { x: '2054.5355', y: '-3518.384098', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-126.04468' },
              },
              thumbnail: '/assets/images/stingray/views/option2_view1.webp',
            },
            {
              label: 'view 2',
              value: {
                Location: { x: '1698.420503', y: '-3764.009917', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-57.419672' },
              },
              thumbnail: '/assets/images/stingray/views/option2_view2.webp',
            },
            {
              label: 'view 3',
              value: {
                Location: { x: '1891.598029', y: '-3387.519688', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-189.775005' },
              },
              thumbnail: '/assets/images/stingray/views/option2_view3.webp',
            },
            {
              label: 'view 4',
              value: {
                Location: { x: '2082.191983', y: '-4159.360516', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-225.113259' },
              },
              thumbnail: '/assets/images/stingray/views/option2_view4.webp',
            },
          ],
          'file A': [
            {
              label: 'view 1',
              value: {
                Location: { x: '3304.595732', y: '-3992.690436', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-231.002564' },
              },
              thumbnail: '/assets/images/stingray/views/filea_view1.webp',
            },
            {
              label: 'view 2',
              value: {
                Location: { x: '2971.578783', y: '-3787.753081', z: '87.999518' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-43.681294' },
              },
              thumbnail: '/assets/images/stingray/views/filea_view2.webp',
            },
            {
              label: 'view 3',
              value: {
                Location: { x: '3148.500344', y: '-3401.177631', z: '87.999516' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-189.775005' },
              },
              thumbnail: '/assets/images/stingray/views/filea_view3.webp',
            },
            {
              label: 'view 4',
              value: {
                Location: { x: '3325.551721', y: '-4229.127418', z: '87.999521' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-220.220438' },
              },
              thumbnail: '/assets/images/stingray/views/filea_view4.webp',
            },
          ],
          'option 3': [
            {
              label: 'view 1',
              value: {
                Location: { x: '4577.680316', y: '-4065.166021', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-231.66424' },
              },
              thumbnail: '/assets/images/stingray/views/option3_view1.webp',
            },
            {
              label: 'view 2',
              value: {
                Location: { x: '4415.934534', y: '-3508.785708', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-139.999999' },
              },
              thumbnail: '/assets/images/stingray/views/option3_view2.webp',
            },
            {
              label: 'view 3',
              value: {
                Location: { x: '4422.431705', y: '-3404.672328', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-189.775005' },
              },
              thumbnail: '/assets/images/stingray/views/option3_view3.webp',
            },
            {
              label: 'view 4',
              value: {
                Location: { x: '4600.974946', y: '-4233.247025', z: '87.99952' },
                Rotation: { pitch: '-11.57026', roll: '0.0', yaw: '-221.66424' },
              },
              thumbnail: '/assets/images/stingray/views/option3_view4.webp',
            },
          ],
        },
      },
      guidedTours: {
        ui: 'interaction',
        type: 'controlGroup',
        mainLabel: 'Guided tours',
        children: {
          guidedToursSpeed: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: 1,
            objectPath: 'PlaybackSpeed',
            options: [
              {
                label: '>',
                value: 1,
              },
              {
                label: '>>',
                value: 1.5,
              },
              {
                label: '>>>',
                value: 1.75,
              },
              {
                label: '>>>>',
                value: 2,
              },
            ],
          },
          guidedTours: {
            ui: 'interaction',
            type: 'singleSelect',
            defaultValue: null,
            objectPath: 'TourNumber',
            context: 'spaces',
            options: {
              'option 1': [
                {
                  label: 'start tour',
                  value: 1,
                  thumbnail: '/assets/images/stingray/guided_tours/option1.webp',
                },
              ],
              'option 2': [
                {
                  label: 'start tour',
                  value: 2,
                  thumbnail: '/assets/images/stingray/guided_tours/option2.webp',
                },
              ],
              'file A': [
                {
                  label: 'start tour',
                  value: 3,
                  thumbnail: '/assets/images/stingray/guided_tours/filea.webp',
                },
              ],
              'option 3': [
                {
                  label: 'start tour',
                  value: 4,
                  thumbnail: '/assets/images/stingray/guided_tours/option3.webp',
                },
              ],
            },
          },
        },
      },
    },
    Environment: {
      scenario: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Scenario',
        defaultValue: 'Show',
        objectPath: 'Configurator',
        options: [
          {
            label: 'bath option 1',
            value: 'Show',
          },
          {
            label: 'bath option 2',
            value: 'Hide',
          },
        ],
      },
      people: {
        ui: 'interaction',
        type: 'controlGroup',
        mainLabel: 'People',
        children: {
          showPeople: {
            ui: 'interactionUE',
            type: 'switch',
            mainLabel: 'Show people',
            defaultValue: true,
            object: {
              CallFunctionRequest: {
                FunctionName: 'ShowAsset',
                Arguments: null,
              },
            },
            objectPath: 'CallFunctionRequest.Arguments',
            multipleArguments: 'people {value}',
          },
          addPeople: {
            ui: 'interactionUE',
            type: 'button',
            mainLabel: 'Add people',
            defaultValue: null,
            object: {
              CallFunctionRequest: {
                FunctionName: 'AddAsset',
                Arguments: 'people',
              },
            },
          },
        },
      },
    },
    Information: {
      interactions: {
        ui: 'interaction',
        type: 'multiSelect',
        mainLabel: 'Interactions',
        defaultValue: ['Default'],
        objectPath: 'FilterTargets',
        options: [
          { label: 'show all', value: 'ShowAll' },
          { label: 'doors', value: 'Default' },
          { label: 'hide all', value: 'HideAll' },
        ],
      },
    },
    Tools: {
      cameraMode: {
        ui: 'interaction',
        type: 'button',
        mainLabel: 'Camera mode',
        defaultValue: null,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetCameraMode',
            Arguments: null,
          },
        },
      },
      // measurementMode: {
      //   ui: 'interaction',
      //   type: 'button',
      //   mainLabel: 'Measurement Tool',
      //   defaultValue: null,
      //   objectPath: 'CallFunctionRequest.Arguments',
      //   object: {
      //     CallFunctionRequest: {
      //       FunctionName: 'ToggleMeasurementTool',
      //       Arguments: null,
      //     },
      //   },
      // },
      slicingMode: {
        ui: 'interaction',
        type: 'button',
        mainLabel: 'Slicing Tool',
        defaultValue: null,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetSlicingToolMode',
            Arguments: null,
          },
        },
      },
    },
    'General Settings': {
      ShouldHideSidebar: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Hide nav on inactivity',
        defaultValue: false,
        objectPath: 'GeneralSettings.ShouldHideSidebar',
      },
      InvertLookX: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Invert x-axis',
        defaultValue: false,
        objectPath: 'GeneralSettings.InvertLookX',
      },
      InvertLookY: {
        ui: 'interactionUE',
        type: 'switch',
        mainLabel: 'Invert y-axis',
        defaultValue: false,
        objectPath: 'GeneralSettings.InvertLookY',
      },
      isRightSidebar: {
        ui: 'interactionWeb',
        type: 'switch',
        mainLabel: 'Sidebar on right',
        defaultValue: false,
      },
      showQuickCapture: {
        ui: 'interactionWeb',
        type: 'switch',
        mainLabel: 'Show quick capture',
        defaultValue: false,
      },
      // showJoysticks: {
      //   ui: 'interactionWeb',
      //   type: 'switch',
      //   mainLabel: 'Show joysticks',
      // defaultValue: false,

      // },
      LookSensitivity: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Look sensitivity',
        defaultValue: 2,
        objectPath: 'GeneralSettings.LookSensitivity',
        options: [
          {
            label: 'low',
            value: 1,
          },
          {
            label: 'medium',
            value: 2,
          },
          {
            label: 'high',
            value: 3,
          },
        ],
      },
      FieldOfView: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Field of view',
        defaultValue: 75,
        objectPath: 'GeneralSettings.FieldOfView',
        options: [
          {
            label: 'low',
            value: 25,
          },
          {
            label: 'medium',
            value: 75,
          },
          {
            label: 'high',
            value: 100,
          },
        ],
      },
      eyeHeight: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Walk mode eye height',
        defaultValue: 160,
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetPawnEyeHeight',
            Arguments: null,
          },
        },
        options: [
          {
            label: 'lounging',
            value: 90,
          },
          {
            label: 'sitting',
            value: 110,
          },
          {
            label: 'standing',
            value: 160,
          },
        ],
      },
      orbitModeBackground: {
        ui: 'interaction',
        type: 'singleSelect',
        mainLabel: 'Orbit mode background',
        defaultValue: 'environment grey',
        objectPath: 'CallFunctionRequest.Arguments',
        object: {
          CallFunctionRequest: {
            FunctionName: 'SetOrbitBackground',
            Arguments: null,
          },
        },
        options: [
          {
            label: 'grey',
            value: 'environment grey',
          },
          {
            label: 'black',
            value: 'environment black',
          },
          {
            label: 'white',
            value: 'environment white',
          },
        ],
      },
    },
  },
};

export default stingrayConfig;
